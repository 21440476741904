import * as Sentry from "@sentry/react";

if (window.SENTRY_DSN) {
  Sentry.init({
	dsn: window.SENTRY_DSN,
	integrations: [
      Sentry.replayIntegration(),
	],
	ignoreErrors: ["Invalid site key or not loaded", "recaptcha"],

	// Capture Replay for 10% of all sessions
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: window.SENTRY_SAMPLE_RATE,
	environment: window.ENVIRONMENT,
  });
} else if (!window.LOCAL_DEV) {
  console.warn("Sentry error logging disabled");
}
